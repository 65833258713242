import { useEffect, useState } from "react";

export const useScreenDetector = () => {
    const [width, setWidth] = useState((window as any)?.innerWidth);

    const handleWindowSizeChange = () => {
        setWidth((window as any)?.innerWidth);
    };

    useEffect(() => {
        (window as any)?.addEventListener("resize", handleWindowSizeChange);

        return () => {
            (window as any)?.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    const isMobile = width <= 768;
    const isTablet = width <= 1024;
    const isDesktop = width > 1024;

    return { isMobile, isTablet, isDesktop };
};
