import classNames from "classnames";
import type { ButtonHTMLAttributes } from "react";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    loading?: boolean;
}

export default function Button({ loading, children, className, ...restProps }: ButtonProps) {
    return (
        <button
            className={classNames(
                " w-full flex items-center hover:text-core  justify-center cursor-pointer disabled:bg-zinc-800  text-black font-medium   disabled:text-primary  py-2 px-2 md:py-3 md:px-3 rounded-lg focus:outline-none focus:shadow-outline disabled:opacity-75 disabled:cursor-not-allowed",
                className ? className : "bg-core ",
            )}
            {...restProps}
            type={restProps.type ? restProps.type : "button"}
        >
            {loading && (
                <svg
                    className="animate-spin  mr-3 h-5 w-5 text-primary"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                    ></circle>
                    <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                </svg>
            )}
            <>{loading ? "" : children}</>
        </button>
    );
}
