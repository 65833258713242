/* eslint-disable no-console */
// import fortmaticModule from "@web3-onboard/fortmatic";
// import gnosisModule from "@web3-onboard/gnosis";
import injectedModule from "@web3-onboard/injected-wallets";
// import keepkeyModule from "@web3-onboard/keepkey";
// import keystoneModule from "@web3-onboard/keystone";
// import ledgerModule from "@web3-onboard/ledger";
// import portisModule from "@web3-onboard/portis";
// import torusModule from "@web3-onboard/torus";
// import trezorModule from "@web3-onboard/trezor";
import walletConnectModule from "@web3-onboard/walletconnect";
import coinbaseModule from "@web3-onboard/coinbase";
// import trustModule from "@web3-onboard/trust";
import metamaskSDK from "@web3-onboard/metamask";
import xdefiWalletModule from "@web3-onboard/xdefi";

// import magicModule from "@web3-onboard/magic";
// import dcentModule from "@web3-onboard/dcent";
// import mewModule from "@web3-onboard/mew-wallet";
// import sequenceModule from "@web3-onboard/sequence";
// import tallyHoWalletModule from "@web3-onboard/tallyho";

// import transactionPreviewModule from '@web3-onboard/transaction-preview'

// import web3authModule from '@web3-onboard/web3auth'

import { init } from "@web3-onboard/react";
import { chains, dAppConfigMetaData } from "utils/configs";

// Example key • Replace with your infura key
// const INFURA_KEY = import.meta.env.VITE_INFURA_KEY;

const metamaskSDKWallet = metamaskSDK({
    options: {
        extensionOnly: false,
        dappMetadata: {
            name: dAppConfigMetaData.description,
        },
    },
});

const injected = injectedModule({
    custom: [
        // include custom injected wallet modules here
    ],
    filter: {
        // mapping of wallet labels to filter here
    },
});

const walletLink = coinbaseModule({ darkMode: true });

const wcInitOptions = {
    /**
     * Project ID associated with [WalletConnect account](https://cloud.walletconnect.com)
     */
    projectId: "6e8f5ba2da6f9d2ff0d78fe85d05163f",
    /**
     * Chains required to be supported by all wallets connecting to your DApp
     */
    requiredChains: [1],
    /**
     * Defaults to `appMetadata.explore` that is supplied to the web3-onboard init
     * Strongly recommended to provide atleast one URL as it is required by some wallets (i.e. MetaMask)
     * To connect with WalletConnect
     */
    dappUrl: "https://claim.teleportdao.xyz",
};
const walletConnect = walletConnectModule(wcInitOptions);
// const portis = portisModule({
//     // Replace with your apiKey
//     apiKey: "b2b7586f-2b1e-4c30-a7fb-c2d1533b153b",
// });

// const fortmatic = fortmaticModule({
//     // Replace with your apiKey
//     apiKey: "pk_test_886ADCAB855632AA",
// });

// const torus = torusModule();
// const ledger = ledgerModule();
// const trust = trustModule();
// const keepkey = keepkeyModule();
// const keystone = keystoneModule();
// const gnosis = gnosisModule();
// const dcent = dcentModule();
// const mew = mewModule();
// const talltHoWalletSdk = tallyHoWalletModule();
// const web3auth = web3authModule({
//   clientId:
//     'DJuUOKvmNnlzy6ruVgeWYWIMKLRyYtjYa9Y10VCeJzWZcygDlrYLyXsBQjpJ2hxlBO9dnl8t9GmAC2qOP5vnIGo'
// })

// const sequence = sequenceModule({
//     appName: "My app",
// });

const trezorOptions = {
    email: "test@test.com",
    appUrl: "https://www.blocknative.com",
};
// const trezor = trezorModule(trezorOptions);
const xdefiSdk = xdefiWalletModule();

// const magic = magicModule({
//     // Replace with your apiKey
//     apiKey: "pk_live_02207D744E81C2BA",
// });

// const transactionPreview = transactionPreviewModule({});

export default init({
    theme: "dark",
    // transactionPreview,
    apiKey: "fea7d799-8061-4bfe-9edc-f8794fbbb269",
    // An array of wallet modules that you would like to be presented to the user to select from when connecting a wallet.
    wallets: [
        injected,
        metamaskSDKWallet,

        // gnosis,
        // fortmatic,
        // portis,
        walletLink,
        // magic,
        // torus,
        // ledger,
        // trezor,
        walletConnect,
        // trust,
        xdefiSdk,
        // keepkey,
        // keystone,
        // dcent,
        // mew,
        // talltHoWalletSdk,
        // web3auth,
        // sequence
    ],
    // An array of Chains that your app supports
    chains: chains,
    notify: {
        desktop: {
            enabled: false,
            transactionHandler: (transaction) => {
                console.log({ transaction });

                // if (transaction.eventCode === 'txPool') {
                //   return {
                //     type: 'success',
                //     message: 'Your transaction from #1 DApp is in the mempool',
                //   }
                // }
            },
            position: "bottomRight",
        },
        mobile: {
            enabled: false,
            transactionHandler: (transaction) => {
                console.log({ transaction });

                // if (transaction.eventCode === 'txPool') {
                //   return {
                //     type: 'success',
                //     message: 'Your transaction from #1 DApp is in the mempool',
                //   }
                // }
            },
            position: "bottomRight",
        },
    },
    accountCenter: {
        desktop: {
            position: "topRight",
            enabled: false,
            minimal: false,
        },
        mobile: {
            position: "topRight",
            enabled: false,
            minimal: false,
        },
    },
    appMetadata: {
        // The name of your dApp
        name: "Teleport",
        // SVG icon string, with height or width (whichever is larger) set to 100% or a valid image URL
        icon: "<svg></svg>",
        // Optional wide format logo (ie icon and text) to be displayed in the sidebar of connect modal. Defaults to icon if not provided
        logo: "<svg></svg>",
        // The description of your app
        description: "Teleport DAPP",
        // The url to a getting started guide for app
        gettingStartedGuide: "https://teleswap.xyz/",
        // url that points to more information about app
        explore: "https://teleswap.xyz/",
        // if your app only supports injected wallets and when no injected wallets detected, recommend the user to install some
        recommendedInjectedWallets: [
            {
                // display name
                name: "MetaMask",
                // link to download wallet
                url: "https://metamask.io",
            },
            { name: "Coinbase", url: "https://wallet.coinbase.com/" },
        ],
        // Optional - but allows for dapps to require users to agree to TOS and privacy policy before connecting a wallet
        agreement: {
            version: "1.0.0",
            termsUrl: "https://teleswap.xyz//terms-conditions",
            privacyUrl: "https://teleswap.xyz//privacy-policy",
        },
    },
    // example customizing copy
    // i18n: {
    //   en: {
    //     connect: {
    //       selectingWallet: {
    //         header: 'custom text header'
    //       }
    //     }
    //   }
    // }
});
