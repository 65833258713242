import mixpanel from "mixpanel-browser";
import { environment } from "./configs";

export function MixPanel() {
    const token = import.meta.env.VITE_MIXPANEL_ID as string;

    const init = () => {
        // if (environment === "mainnet") {
        mixpanel.init(token, {
            debug: import.meta.env.DEV ? true : false,
            track_pageview: environment === "mainnet" ? true : false,
            persistence: "localStorage",
        });
        // }
    };

    const trackEvent = (
        category: string,
        action: string,
        actionLabel = "none",
        describerValue = 0,
        nonInteraction = false,
    ) => {
        if (environment === "mainnet") {
            mixpanel.track(category, {
                actionLabel: actionLabel,
            });
        }
    };

    const setUser = (userWalletAddress: string) => {
        if (environment === "mainnet") {
            mixpanel.identify(userWalletAddress);
            mixpanel.people.set({
                walletAddress: userWalletAddress,
            });
        }
    };

    return {
        init,
        trackEvent,
        setUser,
    };
}
