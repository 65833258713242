import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";
import { Paths } from "./paths";
import Layout from "components/Layout";
import OtherLayout from "components/OtherLayout";
import { projectName } from "utils/configs";

export function isAuthPathname(pathname: string): boolean {
    return ["Paths"].includes(pathname);
}

const router = createBrowserRouter(
    !projectName.includes("investor")
        ? [
              {
                  path: "/",
                  element: <Layout />,
                  children: [
                      {
                          index: true,
                          async lazy() {
                              let { CCExchange } = await import("../pages/cc-exchange");
                              return { Component: CCExchange };
                          },
                      },
                      {
                          path: Paths.CrossChainExchange,
                          async lazy() {
                              let { CCExchange } = await import("../pages/cc-exchange");
                              return { Component: CCExchange };
                          },
                      },
                      {
                          path: Paths.CrossChainExchange,
                          async lazy() {
                              let { CCExchange } = await import("../pages/cc-exchange");
                              return { Component: CCExchange };
                          },
                      },
                      {
                          path: Paths.CrossChainExchangeOld,
                          element: <Navigate to={Paths.CrossChainExchange} replace />,
                      },
                      {
                          path: Paths.AccountManagement,
                          async lazy() {
                              let { TransactionHistory } = await import("../pages/transaction-history");
                              return { Component: TransactionHistory };
                          },
                      },
                      {
                          path: Paths.Dashboard,
                          async lazy() {
                              let { Dashboard } = await import("../pages/dashboard");
                              return { Component: Dashboard };
                          },
                      },
                      {
                          path: Paths.LiquidityPools,
                          async lazy() {
                              let { LiquidityPools } = await import("../pages/liquidity-pools");
                              return { Component: LiquidityPools };
                          },
                      },
                      {
                          path: Paths.Staking,
                          async lazy() {
                              let { StakingPage } = await import("../pages/staking");
                              return { Component: StakingPage };
                          },
                      },
                      {
                          path: Paths.referralStats,
                          async lazy() {
                              let { ReferralStats } = await import("../components/ReferralStats");
                              return { Component: ReferralStats };
                          },
                      },
                      {
                          path: "*",
                          element: <div>Not Found page...</div>,
                      },
                  ],
              },
              {
                  path: "/other",
                  element: <Layout />,
                  children: [
                      {
                          index: true,
                          path: Paths.Faqs,
                          async lazy() {
                              let { Faqs } = await import("../pages/faqs");
                              return { Component: Faqs };
                          },
                      },
                      {
                          path: "*",
                          element: <div>Not Found page...</div>,
                      },
                  ],
              },
              {
                  path: "/invest",
                  element: <OtherLayout />,
                  children: [
                      {
                          index: true,
                          path: Paths.Invest,
                          async lazy() {
                              let { InvestPage } = await import("../pages/invest");
                              return { Component: InvestPage };
                          },
                      },
                      {
                          path: "*",
                          element: <div>Not Found page...</div>,
                      },
                  ],
              },
          ]
        : [
              {
                  path: "/",
                  element: <OtherLayout />,
                  children: [
                      {
                          index: true,
                          async lazy() {
                              let { InvestPage } = await import("../pages/invest");
                              return { Component: InvestPage };
                          },
                      },
                      {
                          path: "*",
                          element: <div>Not Found page...</div>,
                      },
                  ],
              },
          ],
);
export const RoutesCal = () => {
    return <RouterProvider router={router} fallbackElement={<p></p>} />;
};
