import { sourceNetwork } from "./configs";
import { removeExtraZeros, toTrunc, truncateNumber } from "./convert";

const isTestnet = sourceNetwork.name.includes("test");

const validNumberRegex = new RegExp("^[0-9]+$");

const btcMainnetAddressRegex = /^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,39}$/;
const btcTestnetAddressRegex = /^[mn][a-km-zA-HJ-NP-Z0-9]{25,34}$/;
const bech32TestnetAddressRegex = /^(tb1)[a-z0-9]{1,83}$/;
const taprootMainnetAddressRegex = /^(bc)1[02-9ac-hj-np-z]{25,89}$/i;
const taprootTestnetAddressRegex = /^(tb)1[02-9ac-hj-np-z]{25,89}$/i;
const ethereumAddressRegex = /^(0x)?[0-9a-fA-F]{40}$/;

const extractNumbers = (str: string): string[] => {
    const string = str + "";
    return string.match(/\d+/g) || [];
};
const parseValueToNumber = (value: string): string => {
    return value?.indexOf(",") >= 0 ? (value as any)?.replaceAll(",", "") : value;
};

const formatNumberWithCommas = (_value: number | string | any = ""): string => {
    let value = _value?.toString();
    // return value?.toString()?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    if ([null, undefined].includes(value)) {
        return "";
    }
    if (value?.startsWith(".")) {
        return "0.";
    }

    const [firstPart = "", ...parts] = value.toString().split(".");
    const formatted = firstPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return [formatted, ...(parts || [])].join(".");
};
const isValidNumber = (value: number | string): boolean => {
    let _value = value.toString();
    _value = _value?.indexOf(",") >= 0 ? (_value as any)?.replaceAll(",", "") : _value;
    if (_value === "00") {
        return false;
    }
    _value = _value?.indexOf(".") >= 0 ? _value?.replace(".", "") : _value;

    const dotIndex = value?.toString()?.indexOf(".");
    const realDecimal = dotIndex > 0 ? value?.toString()?.substring(dotIndex + 1).length : 0;

    if (_value === "") {
        return true;
    } else if (realDecimal > 18) {
        return false;
    } else {
        return validNumberRegex.test(_value);
    }
};
const isValidEthereumAddress = (address: string): boolean => {
    return ethereumAddressRegex.test(address) && address !== "0x0000000000000000000000000000000000000000";
};
const isValidBitcoinAddress = (address: string): boolean => {
    if (isTestnet) {
        return (
            btcTestnetAddressRegex.test(address) ||
            bech32TestnetAddressRegex.test(address) ||
            taprootTestnetAddressRegex.test(address)
        );
    } else {
        return btcMainnetAddressRegex.test(address) || taprootMainnetAddressRegex.test(address);
    }
};
function isNaN(x: any) {
    // Coerce into number
    x = Number(x);
    // if x is NaN, NaN != NaN is true, otherwise it's false
    return x != x;
}
function convertExponentialToDecimal(exponential: any, decimal: any) {
    if (isNaN(exponential)) {
        return 0;
    }
    if (Math.abs(exponential) < 1.0) {
        let e = parseInt(exponential.toString().split("e-")[1]);
        if (e) {
            exponential *= Math.pow(10, e - 1);
            exponential = "0." + new Array(e).join("0") + exponential.toString().substring(2);
        }
    } else {
        let e = parseInt(exponential.toString().split("+")[1]);
        if (e > 20) {
            e -= 20;
            exponential /= Math.pow(10, e);
            exponential += new Array(e + 1).join("0");
        }
    }
    return removeExtraZeros(toTrunc(exponential, decimal));
}
const convertToNumber = (number: any) => {
    return Number(number) >= 1
        ? Number(number)
              //   .toFixed(realDecimal > 8 ? 8 : realDecimal)
              .toLocaleString()
        : convertExponentialToDecimal(number, 18);
};
export {
    convertToNumber,
    extractNumbers,
    parseValueToNumber,
    formatNumberWithCommas,
    isValidNumber,
    isValidEthereumAddress,
    isValidBitcoinAddress,
    convertExponentialToDecimal,
};
