import { ToastWrapper } from "components/Toast/toast-wrapper";
import useToast from "utils/useToast";
const Toast = () => {
    const { state, clearById } = useToast();
    if (state.messages.length <= 0) return null;
    else
        return (
            <div>
                {state?.messages?.map((message: any) => {
                    const { content, type, id } = message;
                    return (
                        <ToastWrapper
                            key={id}
                            type={type}
                            onClose={() => {
                                clearById(id);
                            }}
                            content={content}
                        />
                    );
                })}
            </div>
        );
};

export default Toast;
