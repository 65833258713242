export enum NetworkTypeDTO {
    polygon = "Polygon",
    binance = "BNB",
    ethereum = "Ethereum",
    arbitrum = "Arbitrum",
    optimism = "Optimism",
    bsquared = "BSquared",
    bob = "BOB",
}

export enum FAQsTypes {
    General = 0,
    Bridging = 1,
    Trading = 2,
}
export enum FAQsTypesConvert {
    "General",
    "Bridging",
    "Trading",
}
