/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import onboard from "../ web3-onboard";
import { Web3OnboardProvider } from "@web3-onboard/react";
import { useEffect } from "react";
import { WebWalletProvider } from "states/webWalletContext";
import { setUser } from "utils/analytics";
import useStorage from "utils/useStorage";

export default function ProviderWeb3Wallet(props: any) {
    let _window: any = null;
    _window = typeof window === "undefined" ? null : window;
    const { setItem, getItem } = useStorage();

    const walletsSub = onboard.state.select("wallets");
    walletsSub.subscribe((wallets) => {
        const connectedWallets = wallets.map(({ label }) => label);
        setItem("connectedWallets", JSON.stringify(connectedWallets));

        wallets[0]?.accounts[0]?.address &&
            !getItem("userUniqueAddress") &&
            setItem("userUniqueAddress", wallets[0]?.accounts[0]?.address);
        wallets[0] && wallets[0].accounts[0] && setUser({ userWalletAddress: wallets[0]?.accounts[0]?.address });
    });

    useEffect(() => {
        const previouslyConnectedWallets =
            _window && _window.JSON.parse(window?.localStorage?.getItem("connectedWallets"));
        (async () => {
            try {
                if (previouslyConnectedWallets?.length > 0) {
                    // Connect the most recently connected wallet (first in the array)

                    //  await onboard.connectWallet({ autoSelect: previouslyConnectedWallets[0] });

                    // You can also auto connect "silently" and disable all onboard modals to avoid them flashing on page load
                    await onboard.connectWallet({
                        autoSelect: { label: previouslyConnectedWallets[0], disableModals: true },
                    });

                    // OR - loop through and initiate connection for all previously connected wallets
                    // note: This UX might not be great as the user may need to login to each wallet one after the other
                    // for (walletLabel in previouslyConnectedWallets) {
                    //   await onboard.connectWallet({ autoSelect: walletLabel })
                    // }
                }
            } catch (error) {
                console.log(error);
            }
        })();

        // Always keep this hook with the single dependency.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onboard]);

    return (
        <Web3OnboardProvider web3Onboard={onboard}>
            <WebWalletProvider {...props} />
        </Web3OnboardProvider>
    );
}
