import { useReducer, createContext } from "react";
const ACTION_TYPES = {
    ADD_TOAST: "addToast",
    CLEAR__ALL_TOASTS: "clearAllToast",
    CLEAR_TOAST: "clearToast",
};
const initialState = {
    messages: [],
};

const ToastContext = createContext(initialState as any);

const reducer = (state: any, action: any) => {
    const { type, value } = action;
    switch (type) {
        case ACTION_TYPES.ADD_TOAST:
            return {
                messages: [value],
            };
        case ACTION_TYPES.CLEAR_TOAST:
            return {
                messages: [...state.messages.filter((item: any) => item.id !== value)],
            };
        case ACTION_TYPES.CLEAR__ALL_TOASTS:
            return {
                messages: [],
            };

        default:
            return state;
    }
};
const ToastProvider = (props: any) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const value = { state, dispatch };
    return <ToastContext.Provider value={value}>{props.children}</ToastContext.Provider>;
};
export { ToastProvider, ToastContext };
