import { useReducer, createContext, useContext } from "react";
import { isTestnet } from "utils/configs";
import { GoogleAnalytics } from "utils/googleAnalytics";

const initialState = {
    walletOptions: { show: false, value: "bitcoin-evm" },
    poolsFilters: { label: "Collateral", value: "collateral" },
    isAuthenticated: isTestnet ? window.localStorage.getItem("USER_Authenticated") : true,
    provider: null,
    ledgerBitcoinApp: null,
    trezorBitcoinApp: null,
    bitcoinAppAccounts: null,
    searchFilter: {
        address: null,
        type: "BTC",
        status: "All",
        cursorDetails: { current: "zero", list: [] },
    },
    referralAddress: null,
};

const GlobalStateContext = createContext(initialState as any);
const GlobalDispatchContext = createContext((() => 0) as React.Dispatch<any>);

const globalReducer = (state: any, action: any) => {
    let _window: any = null;
    _window = typeof window === "undefined" ? null : window;

    switch (action.type) {
        case "setProvider":
            return { ...state, provider: action.value };
        case "setWalletOptions":
            action.value.show === true && GoogleAnalytics().trackGTMEventWallet();
            return { ...state, walletOptions: action.value };
        case "setSearchFilter":
            return { ...state, searchFilter: action.value };
        case "setCursorDetails":
            return {
                ...state,
                searchFilter: {
                    ...state.searchFilter,
                    cursorDetails: action.value,
                },
            };
        case "toggleWalletOptions":
            state.walletOptions.show === false && GoogleAnalytics().trackGTMEventWallet();
            return { ...state, walletOptions: { show: !state.walletOptions.show, value: action.value } };
        case "setIsAuthenticated":
            return { ...state, isAuthenticated: action.value };
        case "setLedgerBitcoinApp":
            return { ...state, ledgerBitcoinApp: action.value, bitcoinAppAccounts: action?.otherValue };
        case "setTrezorBitcoinApp":
            return { ...state, trezorBitcoinApp: action.value, bitcoinAppAccounts: action?.otherValue };
        case "setBitcoinAppAccounts":
            return { ...state, bitcoinAppAccounts: action.value };
        case "setReferralAddress":
            return { ...state, referralAddress: action.value };
        case "setClearBitcoinAppAccounts":
            return {
                ...state,
                bitcoinAppAccounts: null,
            };

        default:
            throw new Error(`Unexpected action: ${action.type}`);
    }
};

const GlobalProvider = (props: any) => {
    const [globalState, DispatchGlobalState] = useReducer(globalReducer, {
        ...initialState,
        ...props?.value,
    });
    return (
        <GlobalStateContext.Provider value={globalState}>
            <GlobalDispatchContext.Provider value={DispatchGlobalState}>
                {props.children}
            </GlobalDispatchContext.Provider>
        </GlobalStateContext.Provider>
    );
};

const useGlobalState = () => {
    const context = useContext(GlobalStateContext);
    if (context === undefined) {
        throw new Error("useGlobalState must be used within a GlobalProvider");
    }
    return context;
};

const useGlobalDispatch = () => {
    const context = useContext(GlobalDispatchContext);
    if (context === undefined) {
        throw new Error("useGlobalDispatch must be used within a GlobalProvider");
    }
    return context;
};

export { GlobalProvider, useGlobalState, useGlobalDispatch };
