/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-const */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";

interface TimeRemaining {
    total: number;
    days: string | number;
    hours: string | number;
    minutes: string | number;
    seconds: string | number;
}

interface Props {
    date: Date;
    onRefresh?: any;
}

const CountdownTimer: React.FC<Props> = ({ date }) => {
    const [timeRemaining, setTimeRemaining] = useState<TimeRemaining>(getTimeRemaining(date));
    const latestDate = useRef(date);
    let latestDateAgain: any = useRef(0);
    useEffect(() => {
        latestDate.current = date;
    }, [date]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (Math.sign(+getTimeRemaining(latestDate.current)?.total) === 1) {
                setTimeRemaining(getTimeRemaining(latestDate.current));
            } else {
                if (
                    latestDateAgain.current !== 0 &&
                    Math.sign(+getTimeRemaining(latestDateAgain.current)?.total) === 1
                ) {
                    setTimeRemaining(getTimeRemaining(latestDateAgain.current));
                } else {
                    latestDateAgain.current = new Date(Date.now());
                    setTimeRemaining(getTimeRemaining(new Date(Date.now())));
                }

                // onRefresh && onRefresh();
                // clearInterval(intervalId);
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    function getTimeRemaining(endTime: Date): TimeRemaining {
        const total = endTime.getTime() - Date.now();
        const _seconds = Math.floor((total / 1000) % 60);
        const seconds = _seconds < 10 ? `0${_seconds}` : _seconds;
        const _minutes = Math.floor((total / 1000 / 60) % 60);
        const minutes = _minutes < 10 ? `0${_minutes}` : _minutes;

        const _hours = Math.floor((total / (1000 * 60 * 60)) % 24);
        const hours = _hours < 10 ? `0${_hours}` : _hours;

        const _days = Math.floor(total / (1000 * 60 * 60 * 24));
        const days = _days < 10 ? `0${_days}` : _days;

        return {
            total,
            days,
            hours,
            minutes,
            seconds,
        };
    }

    return (
        <div className="flex">
            {timeRemaining.total < 0 ? (
                "-:-"
            ) : (
                <>
                    {" "}
                    {/* <div>{`${timeRemaining.days}`}:</div>
                    <div>{`${timeRemaining.hours}`}:</div> */}
                    <div>{`${timeRemaining.minutes}`}:</div>
                    <div>{`${timeRemaining.seconds}`}</div>
                </>
            )}
        </div>
    );
};

export default CountdownTimer;
