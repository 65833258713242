import mixpanel from "mixpanel-browser";
import { environment } from "./configs";

export interface Dict {
    [key: string]: any;
}

const trackEvent = ({ category, action, properties }: { category: string; action: string; properties?: Dict[] }) => {
    const _properties = [{ category: category }, ...(properties || [])].reduce((acc, current) => {
        return { ...acc, ...current };
    }, {});

    environment === "mainnet" && mixpanel.track(action, _properties);
};

const setUser = ({ userWalletAddress }: { userWalletAddress: string }) => {
    environment === "mainnet" && mixpanel.identify(userWalletAddress);
    environment === "mainnet" &&
        mixpanel.people.set({
            name: userWalletAddress,
            walletAddress: userWalletAddress,
        });
};

export { trackEvent, setUser };
