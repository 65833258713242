export const Paths = {
    CrossChainExchange: "/bridge",
    CrossChainExchangeOld: "/cc-exchange",
    CrossChainTransfer: "/bridge",
    LiquidityPools: "/farming",
    Staking: "/staking",
    AccountManagement: "/transaction-history",
    Dashboard: "/network-stats",
    referralStats: "/referral-stats",
    Faqs: "/other/faqs",
    Invest: "/invest",
    Home: "/",
    NotFound: "*",
};

export const Links = {};
