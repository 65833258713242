import { useReducer, createContext } from "react";

const initialState = {};

const WebWalletContext = createContext(initialState as any);

const reducer = (state: any, action: any) => {
    const { type } = action;
    switch (type) {
        default:
            return state;
    }
};
const WebWalletProvider = ({ children }: any) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const value = { state, dispatch };
    return (
        <WebWalletContext.Provider value={value}>
            <div className="scroll">{children}</div>
        </WebWalletContext.Provider>
    );
};

WebWalletProvider.defaultProps = {};
export { WebWalletProvider, WebWalletContext };
