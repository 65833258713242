/* eslint-disable @typescript-eslint/no-unused-vars */
import { useSetChain } from "@web3-onboard/react";
import useToast from "./useToast";
import { chainsDetects } from "utils/configs";
import { sleep } from "./functions";
const useWallet = () => {
    const toast = useToast();
    const [
        {
            chains, // the list of chains that web3-onboard was initialized with
            connectedChain, // the current chain the user's wallet is connected to
            settingChain, // boolean indicating if the chain is in the process of being set
        },
        setChain, // function to call to initiate user to switch chains in their wallet
    ] = useSetChain();

    const checkIsValidNetwork = (network: string) => {
        const findChain = chainsDetects.find((chain: any) => chain.chainNetwork === network);
        return connectedChain?.id && findChain && connectedChain?.id === findChain?.id;
    };

    const setValidNetwork = (network: string, callBackFun?: any) => {
        if (!checkIsValidNetwork(network)) {
            const _findChain = chainsDetects.find((chain: any) => chain.chainNetwork === network);
            if (_findChain) {
                const findChain = chains.find((chain) => chain.id === _findChain.id);
                toast.success({
                    topTitle: "Switch Network",
                    title: `Please switch to ${findChain?.label} network`,
                    // actionContent: `Switch to ${findChain?.label}`,
                    // onAction: () => {
                    //     setChain({ chainId: findChain?.id || "" });
                    //     toast.clearAll();
                    // },
                });
                setChain({ chainId: findChain?.id || "" })
                    .then((isChanged: boolean) => {
                        if (isChanged) {
                            toast.clearAll();
                            sleep(1000, () => {
                                callBackFun && callBackFun();
                            });
                        } else {
                            toast.clearAll();
                        }
                    })

                    .catch((error: any) => {
                        toast.clearAll();
                    });
            }
        }
    };

    return {
        checkIsValidNetwork,
        setValidNetwork,
        connectedChain,
    };
};

export default useWallet;
