/* eslint-disable @typescript-eslint/no-unused-vars */
import { Fragment, useEffect, useState } from "react";
import { Tab } from "@headlessui/react";

function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
}

interface TabsProps {
    selectedIndex: number;
    items: {
        key: string;
        value: any;
        content: any;
        extraContent?: any;
    }[];
    onChange?: any;
    className?: any;
    type?: "inline" | "outline";
    bg?: "bg-ground-dark" | "bg-ground-lighter" | "bg-core" | "border-b-4 border-core !rounded-[0]";
    showNav?: boolean;
}

export default function Tabs({
    selectedIndex: _selectedIndex = 0,
    items = [],
    onChange,
    className,
    type = "outline",
    bg = "bg-ground-dark",
    showNav = true,
}: TabsProps) {
    const [selectedIndex, setSelectedIndex] = useState(_selectedIndex);

    useEffect(() => {
        setSelectedIndex(_selectedIndex);
    }, [_selectedIndex]);

    const handleChange = (value: any) => {
        setSelectedIndex(value);
        onChange && onChange(value);
    };
    return (
        <div className="w-full md:py-2 ">
            <Tab.Group selectedIndex={selectedIndex} onChange={handleChange}>
                <Tab.List
                    className={`flex max-w-[max-content] pt-1 pb-4 md:pb-0 space-x-1 px-2 md:px-0 rounded-t-lg overflow-auto ${showNav ? "" : "md:hidden"} `}
                >
                    {items.map((item) => (
                        <Tab
                            key={item.key}
                            className={({ selected }) =>
                                classNames(
                                    `w-fit  max-w-[max-content] whitespace-nowrap border-0 text-sm  font-medium text-primary ${
                                        type === "outline"
                                            ? "rounded-t-lg py-4 px-4 leading-5 "
                                            : "rounded-lg leading-5 py-3 px-4"
                                    } `,
                                    "focus:outline-none  ring-primary ",
                                    selected
                                        ? `${type === "outline" ? "bg-ground" : bg}`
                                        : ` ${
                                              type === "outline" ? "bg-ground-lighter" : ""
                                          } text-gray-lighter hover:bg-ground hover:text-primary `,
                                )
                            }
                        >
                            {item.value}
                        </Tab>
                    ))}
                </Tab.List>

                <Tab.Panels className="">
                    {items.map((item, idx) => (
                        <Tab.Panel
                            key={idx}
                            className={classNames(
                                "rounded-b-lg rounded-t-lg md:rounded-t-none md:p-1 ",
                                "focus:outline-none  text-primary ",
                                className ? className : "",
                            )}
                        >
                            {selectedIndex === idx && item.content}
                        </Tab.Panel>
                    ))}
                </Tab.Panels>
            </Tab.Group>
            {items.map((item, idx) => (
                <Fragment key={`extra-${idx}`}>{selectedIndex === idx && item?.extraContent}</Fragment>
            ))}
        </div>
    );
}
