import { useReducer, createContext, useContext } from "react";

const initialState = {
    provider: null,
};

const SignerStateContext = createContext(initialState as any);
const SignerDispatchContext = createContext((() => 0) as React.Dispatch<any>);

const signerReducer = (state: any, action: any) => {
    switch (action.type) {
        case "setProvider":
            return { ...state, provider: action.value };

        default:
            throw new Error(`Unexpected action: ${action.type}`);
    }
};

const SignerProvider = (props: any) => {
    const [signerState, DispatchSignerState] = useReducer(signerReducer, {
        ...initialState,
        ...props?.value,
    });
    return (
        <SignerStateContext.Provider value={signerState}>
            <SignerDispatchContext.Provider value={DispatchSignerState}>
                {props.children}
            </SignerDispatchContext.Provider>
        </SignerStateContext.Provider>
    );
};

const useSignerState = () => {
    const context = useContext(SignerStateContext);
    if (context === undefined) {
        throw new Error("useSignerState must be used within a SignerProvider");
    }
    return context;
};

const useSignerDispatch = () => {
    const context = useContext(SignerDispatchContext);
    if (context === undefined) {
        throw new Error("useSignerDispatch must be used within a SignerProvider");
    }
    return context;
};

export { SignerProvider, useSignerState, useSignerDispatch };
