import { AxiosRequest } from "interfaces/DTO";
import axios, { AxiosRequestConfig } from "axios";
const useRequest = (): AxiosRequest => {
    const request = axios.create({
        timeout: 10000,
        headers: {
            "content-type": "application/json",
        },
    });

    request.interceptors.response.use(
        (response) => {
            return response?.data ? response?.data : response;
        },
        (error) => {
            return Promise.reject(error);
        },
    );

    const get = <TData>(url: string, config: AxiosRequestConfig): Promise<any> => {
        return config ? request.get(url, config) : request.get(url);
    };
    const deleteRequest = <TData>(url: string): Promise<any> => {
        return request.delete(url);
    };
    const post = <TData, TBody>(url: string, body: TBody, config: AxiosRequestConfig): Promise<any> => {
        return config ? request.post(url, body, config) : request.post(url, body);
    };
    const patch = <TData>(url: string, body: AxiosRequestConfig): Promise<any> => {
        return request.patch(url, body);
    };
    const put = <TData>(url: string, body: AxiosRequestConfig): Promise<any> => {
        return request.put(url, body);
    };

    const all = axios.all;
    const spread = axios.spread;

    return { request, get, post, put, deleteRequest, patch, all, spread };
};

export default useRequest;
