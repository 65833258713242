import { FC } from "react";
import Image from "ui-components/Image";
import { toSliceAddress } from "utils/convert";

interface TransactionAddressProps {
    address: string;
    transactionEndpoint?: string;
    type?: "full" | "half";
    value?: string;
}

const TransactionAddress: FC<TransactionAddressProps> = ({
    address: fullAddressHex,
    transactionEndpoint,
    type = "half",
    value,
}: TransactionAddressProps) => {
    const fullAddress = fullAddressHex;
    const address = fullAddress && fullAddress?.length ? `${toSliceAddress(fullAddress)}` : null;

    return (
        <a
            className="flex hover:underline underline-offset-8 hover:decoration-core "
            href={`${transactionEndpoint}/${fullAddress}`}
            target="_blank"
            rel="noreferrer"
            aria-label={"tx-address"}
        >
            <span className="flex">
                {type === "half" ? (
                    <span className="ui-transaction-address-value-half">{value ? value : address}</span>
                ) : (
                    <span className="ui-transaction-address-value-full">{value ? value : fullAddress}</span>
                )}
                <Image
                    width="10"
                    className=" mx-2 cursor-pointer"
                    height="10"
                    alt="arrow"
                    loading="lazy"
                    src={"/images/open.svg"}
                />
            </span>
        </a>
    );
};

export default TransactionAddress;
