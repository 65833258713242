import Header from "components/Header";
import Footer from "./Footer";
import { Outlet } from "react-router-dom";
import PromotionSlider from "./PromotionSlider";
import { Toast } from "./Toast";
export default function OtherLayout({ children }: any) {
    return (
        <main className="">
            <Toast />
            <div className="flex flex-row">
                <div
                    id="headlessui-portal-main"
                    className="flex min-h-screen justify-between flex-col  items-center container-bg w-full mb-4 "
                >
                    <div className="flex flex-col items-center container-bg w-full  md:max-w-[1000px] ">
                        <Header />
                        <PromotionSlider />
                        <Outlet />
                    </div>

                    {/* <Footer /> */}
                </div>
            </div>
            <div className="fixed right-0 bottom-0 market"></div>
        </main>
    );
}
