import "./styles/globals.css";

import ProviderWeb3Wallet from "components/ProviderWeb3Wallet";
import QueryClientContainer from "QueryClientContainer";
import { ThemeProvider } from "states/themeContext";
import { GlobalProvider } from "states/globalContext";
import { SignerProvider } from "states/signerContext";
import { NotificationProvider } from "states/notificationContext";
import { ToastProvider } from "states/toastContext";
import { RoutesCal } from "./routes";
import { Notification } from "./components/Notification";
import { BitcoinWalletProvider } from "states/BitcoinWalletContextProvider";

export default function App() {
    return (
        <>
            <GlobalProvider>
                <SignerProvider>
                    <ProviderWeb3Wallet>
                        <QueryClientContainer>
                            <BitcoinWalletProvider>
                                <ThemeProvider>
                                    <NotificationProvider>
                                        <ToastProvider>
                                            <RoutesCal />
                                            <Notification />
                                        </ToastProvider>
                                    </NotificationProvider>
                                </ThemeProvider>
                            </BitcoinWalletProvider>
                        </QueryClientContainer>
                    </ProviderWeb3Wallet>
                </SignerProvider>
            </GlobalProvider>
        </>
    );
}
