import { setDigitWithFraction } from "./convert";

export const groupBy = function (xs: any, key: any) {
    return xs.reduce(function (rv: any, x: any) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

export const sortBy = (array: any[], key: string, type = "decs"): any[] => {
    return array.slice().sort((a, b) => {
        const valueA = a[key];
        const valueB = b[key];

        if (valueA > valueB) {
            return type === "decs" ? -1 : 1;
        } else if (valueA < valueB) {
            return type === "decs" ? 1 : -1;
        } else {
            return 0;
        }
    });
};
export const capitalize = (word: any) => {
    return word
        .split("")
        .map((letter: any, index: any) => (index ? letter.toLowerCase() : letter.toUpperCase()))
        .join("");
};

export const littleToBigEndian = (_endian: any = "") => {
    if (!_endian) return undefined;

    const endian = _endian?.startsWith("0x") ? _endian?.replace("0x", "") : _endian;
    const len = endian.length;
    let bigEndianHexString = "";
    for (let i = 0; i < len / 2; i++) {
        bigEndianHexString += endian.substring(len - (i + 1) * 2, len - i * 2);
    }
    return bigEndianHexString;
};
export const sumArray = (array: any) => {
    return array.reduce((total: any, item: any) => total + item);
};

export function numberToWord(number: any) {
    const million = 1000000;
    const thousand = 1000;
    let result;

    if (number >= million) {
        result = (number / million).toFixed(0) + "M";
    } else if (number >= thousand) {
        result = (number / thousand).toFixed(0) + "K";
    } else {
        result = number;
    }

    return result;
}
export const formatNumber = (num: number, precision = 1) => {
    const map = [
        { suffix: "T", threshold: 1e12 },
        { suffix: "B", threshold: 1e9 },
        { suffix: "M", threshold: 1e6 },
        { suffix: "K", threshold: 1e3 },
        { suffix: "", threshold: 1 },
    ];

    const found = map.find((x) => Math.abs(num) >= x.threshold);
    if (found) {
        const formatted = +setDigitWithFraction(num / found.threshold, precision) + found.suffix;
        return formatted;
    }

    return num;
};
function timeout(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function sleep(ms: number, fn: any, ...args: any) {
    await timeout(ms);
    return fn(...args);
}
