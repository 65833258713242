import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";

export function GoogleAnalytics() {
    const GoogleAnalyticsMeasurementCode = import.meta.env.VITE_GA_ID as string; // equivalence to Tracking ID

    const init = () => {
        ReactGA.initialize(GoogleAnalyticsMeasurementCode, {
            testMode: import.meta.env.DEV ? true : false,
        });
        const tagManagerArgs = {
            gtmId: import.meta.env.VITE_GTM_ID || "",
            // dataLayer: {
            //     //   userId: user?.id,
            //     //   userProject: user?.name + " " + user?.last_name,
            // },
        };
        TagManager.initialize(tagManagerArgs);
    };

    const trackGTMEvent = (event: string) => {
        TagManager.dataLayer({
            dataLayer: {
                event: event,
            },
        });
    };
    const trackGTMEventSwap = () => {
        trackGTMEvent("tw-oersk-oersl");
    };
    const trackGTMEventWallet = () => {
        trackGTMEvent("tw-oersk-oesu1");
    };

    const trackPageView = (path: string) => {
        // ReactGA.send(path);
    };

    const trackEvent = (
        category: string,
        action: string,
        actionLabel = "none",
        describerValue = 0,
        nonInteraction = false,
    ) => {
        ReactGA.event({
            category,
            action,
            label: actionLabel,
            value: describerValue,
            nonInteraction,
        });
    };

    return {
        init,
        trackPageView,
        trackEvent,
        trackGTMEvent,
        trackGTMEventSwap,
        trackGTMEventWallet,
    };
}
