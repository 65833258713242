const ErrorMessageList: any = [{ key: "3", value: "transfer amount exceeds balance" }];

export function getErrorMessageDic(code: string) {
    const errorMessage = ErrorMessageList?.find((e: any) => code?.includes(e?.key))
        ? ErrorMessageList?.find((e: any) => code?.includes(e?.key)).value
        : "An unknown error occurred.";

    return errorMessage;
}

const lowBTCBalanceError = {
    btc: "Low BTC balance to cover network fee. Please try with a lower amount.",
    brc20: "Low BTC balance to cover network fee. Please get some BTC.",
};

export function getErrorMessage(error: any, type: "btc" | "brc20" = "btc") {
    if (typeof error === "string" || error instanceof String) {
        return error;
    }
    if (!error) return null;
    const stringifyError = JSON.stringify(error);
    const parseError = JSON.parse(stringifyError);
    const _error = parseError?.data?.data
        ? getErrorMessageDic(parseError?.data?.data)
        : parseError?.error?.data?.message ||
          parseError?.error?.data?.details ||
          parseError?.error?.message ||
          parseError?.reason ||
          error?.response?.data?.message ||
          error?.message ||
          parseError?.code ||
          "An unknown error occurred";
    // if (stringifyError === "{}") return null;

    if (parseError?.data?.message?.includes("insufficient funds for gas")) {
        return "insufficient funds";
    }
    if (error?.message?.includes("JsonRpcEngine")) {
        return "Unknown error from RPC provider";
    }
    if (error?.message?.includes("UserDeclinedError")) {
        return "User Declined";
    } else if (error?.message?.includes("layer") || error?.message?.includes("user closed popup")) {
        return null;
    } else if (_error?.includes("not enough balance")) {
        return lowBTCBalanceError[type];
    } else if (_error?.includes("received amount should be greater than minimumTeleportReceivedAmount")) {
        return "Insufficient Input Amount";
    } else if (_error?.includes("received amount should be greater than minimumBurnReceiveAmount")) {
        return "Insufficient Input Amount";
    } else if (_error?.includes("teleporter percentage fee should be less than 90%")) {
        return "Insufficient Input Amount";
    } else {
        return _error;
    }
}
